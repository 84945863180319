import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import adapter from 'webrtc-adapter';

@Component({
  selector: 'app-video-conference',
  templateUrl: './video-conference.component.html',
  styleUrls: ['./video-conference.component.css']
})
export class VideoConferenceComponent implements OnInit {

  @ViewChild('selfVideo') selfVideo: ElementRef;
  @ViewChild('otherVideo') otherVideo: ElementRef;

  stream: MediaStream;

  pc: RTCPeerConnection;
  answersFrom: any[] = [];

  constructor(private socket: Socket) { }

  ngOnInit(): void {
    this.pc = new RTCPeerConnection({
      iceServers: [{
        urls: 'stun:stun.services.mozilla.com',
        username: 'somename',
        credential: 'somecredentials'
      }]
    });

    this.pc.ontrack = (event: RTCTrackEvent) => {
      console.log(event.streams);
      console.log(event.track);
      if (event.track.kind === 'video') {
        this.otherVideo.nativeElement.srcObject = event.streams[0];
      }
    };

    navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then((stream: MediaStream) => {
      this.selfVideo.nativeElement.srcObject = stream;
      this.stream = stream;
      stream.getTracks().forEach((track: MediaStreamTrack) => this.pc.addTrack(track, stream));
    }, (err) => console.error(err));

    this.socket.on('add-users', (data) => {
      console.log(`new user connected: ${data.users}`);
      this.createOffer(data.users[0]);
    });

    this.socket.on('offer-made', async (data) => {
      console.log(`handle offer-made`);

      await this.pc.setRemoteDescription(new RTCSessionDescription(data.offer));
      const answer: RTCSessionDescriptionInit = await this.pc.createAnswer();
      console.log(`created answer`, answer);
      await this.pc.setLocalDescription(new RTCSessionDescription(answer));

      this.socket.emit('make-answer', { answer, to: data.socket });
    });

    this.socket.on('answer-made', async (data) => {
      console.log(`handle answer-made`);

      await this.pc.setRemoteDescription(new RTCSessionDescription(data.answer));
      if (!this.answersFrom[data.socket]) {
        this.createOffer(data.socket);
        this.answersFrom[data.socket] = true;
      }
    });

    this.socket.connect();
  }

  async createOffer(otherId: string): Promise<void> {
    const offer: RTCSessionDescriptionInit = await this.pc.createOffer();
    console.log(`created offer`, offer);
    await this.pc.setLocalDescription(new RTCSessionDescription(offer));

    this.socket.emit('make-offer', { offer, to: otherId });
  }

}

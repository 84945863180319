import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { VideoConferenceComponent } from './video-conference/video-conference.component';
import { SocketIoModule } from 'ngx-socket-io';

@NgModule({
  declarations: [
    AppComponent,
    VideoConferenceComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    SocketIoModule.forRoot({ url: 'https://e2012ffa.ngrok.io', options: { autoConnect : false } })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
